<template>
  <div>
    
    <v-card elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="6">
                <validation-provider v-slot="{ errors }" vid="nome">
                  <v-text-field
                    v-model="grupoNovo.nome"
                    outlined
                    dense
                    :readonly="readonly"
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Nome"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-left">
            <btn-salvar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.tabelas')
              "
              :carregando="carregandoSalvar"
              @click="$emit('salvar', grupoNovo)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
   
    readonly: {
      type: Boolean,
      default: false,
    },
    grupo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      grupoNovo: {
        nome: null,
      },
    };
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    "grupoNovo.nome"(nome) {
      this.grupoNovo.slug = nome.replace(" ", "-").toLowerCase();
    },

    grupo(grupo) {
      this.grupoNovo = this.slug;
      this.grupoNovo = grupo;
    },
  },
};
</script>
